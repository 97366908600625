.navbar {
  height: 5rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: white;
  z-index: 200;
  -webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
}
.navbar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  background-color: white;
}
.navbar__logo {
  padding: 0;
  padding-left: 1rem;
  max-width: 15rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar__logo--icon {
  cursor: pointer;
  padding-right: 1rem;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  color: #a93e73;
}
.navbar__logo--icon:hover {
  color: #bb4680;
}
.navbar__logo--image {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.navbar__filters {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 200px);
  height: 5rem;
  max-width: 100rem;
  padding-left: 4rem;
}
.navbar__filters .wrapper {
  height: 5rem;
  margin-right: 0.1rem;
}
.navbar__filters .wrapper .select-input .__control {
  height: 5rem;
  border-bottom: none;
}
.navbar__filters .wrapper .select-input .__indicator-separator {
  opacity: 0;
}
.navbar__filters .icon-datepicker {
  position: relative;
}
.navbar__filters .icon-datepicker:hover {
  -webkit-box-shadow: 0 0 1px 1px #a93e73;
  -moz-box-shadow: 0 0 1px 1px #a93e73;
  box-shadow: 0 0 1px 1px #a93e73;
}
.navbar__filters .icon-datepicker .datepicker-icon {
  color: #a93e73;
  top: 1.5rem;
  right: 1.5rem;
}
.navbar__filters .icon-datepicker .react-datepicker-wrapper {
  height: 100%;
}
.navbar__filters .icon-datepicker .react-datepicker-wrapper .react-datepicker__input-container {
  border-bottom: none;
  height: 100%;
}
.navbar__filters .icon-datepicker .react-datepicker-wrapper .react-datepicker__input-container .date-picker {
  padding: 0.9rem 1.5rem;
}
.navbar__text {
  color: #999;
  font-weight: 600;
  font-size: 1rem;
  min-width: max-content;
  padding-right: 1.5rem;
}
@supports not (min-width: max-content) {
  .navbar__text {
    min-width: 12rem;
  }
}
.navbar__buttons {
  margin-left: auto;
  margin-right: 0;
  display: inline-flex;
  align-items: center;
  padding-right: 4rem;
}
.navbar__buttons .xa-button,
.navbar__buttons .select-container {
  display: none;
}
.navbar__buttons .select-container {
  z-index: 1;
}
.navbar__buttons--link {
  color: #ffffff;
}
.navbar__buttons--link:hover {
  color: #ffffff;
}
.navbar__buttons .config-icon-container {
  width: 5rem;
  height: 5rem;
  position: fixed;
  left: 3rem;
  bottom: 3rem;
  display: none;
}
.navbar__buttons .config-icon-container .flaticon-settings {
  color: #fb3350;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
}
.navbar__buttons .config-icon-container .flaticon-settings::before {
  font-size: 4.8rem;
  display: block;
  -webkit-transition: transform 0.5s linear;
  -moz-transition: transform 0.5s linear;
  -ms-transition: transform 0.5s linear;
  -o-transition: transform 0.5s linear;
  transition: transform 0.5s linear;
  transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
}
.navbar__buttons .config-icon-container .flaticon-settings:hover {
  color: #fc657b;
}
.navbar__buttons .config-icon-container .flaticon-settings:hover::before {
  transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
}
.navbar .threads {
  display: block;
  position: fixed;
  right: 2rem;
  top: 1.5rem;
}
.navbar .threads .thread {
  background-color: black;
}
.navbar .threads:hover .thread {
  background-color: #a93e73;
}
@media screen and (max-width: 1300px) {
  .navbar__filters .icon-datepicker {
    width: auto;
    min-width: 15rem;
  }
}
@media screen and (max-width: 1100px) {
  .navbar__text {
    display: none;
  }
}
.navbar-opened {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  align-items: flex-start;
  background-color: none !important;
}
.navbar-opened .navbar-container {
  flex-wrap: wrap;
  width: 100%;
}
.navbar-opened .navbar__buttons {
  position: fixed;
  right: 0;
  top: 5rem;
  width: 100%;
  background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  bottom: 0;
  left: auto;
  padding: 0;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 4rem 2rem;
  flex-direction: column;
  -webkit-animation: gradient-flow 0.5s linear;
  -moz-animation: gradient-flow 0.5s linear;
  -ms-animation: gradient-flow 0.5s linear;
  -o-animation: gradient-flow 0.5s linear;
  animation: gradient-flow 0.5s linear;
}
.navbar-opened .navbar__buttons .config-icon-container {
  display: block;
  -webkit-animation: fall-down 0.6s linear;
  -moz-animation: fall-down 0.6s linear;
  -ms-animation: fall-down 0.6s linear;
  -o-animation: fall-down 0.6s linear;
  animation: fall-down 0.6s linear;
}
.navbar-opened .navbar__buttons .select-container {
  display: flex;
  max-width: 15rem;
}
.navbar-opened .navbar__buttons .xa-button {
  display: block;
  margin-bottom: 1.5rem;
  margin-left: 0;
}
.navbar-opened .navbar__buttons .xa-button:last-of-type {
  margin-top: 5rem;
}
.navbar-opened .navbar__buttons .slide-in-nav {
  -webkit-animation: slide-in-nav 0.3s linear;
  -moz-animation: slide-in-nav 0.3s linear;
  -ms-animation: slide-in-nav 0.3s linear;
  -o-animation: slide-in-nav 0.3s linear;
  animation: slide-in-nav 0.3s linear;
  animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  opacity: 0;
}
.navbar-opened .navbar__buttons .slide-in-nav:nth-of-type(1) {
  animation-delay: 0s;
}
.navbar-opened .navbar__buttons .slide-in-nav:nth-of-type(2) {
  animation-delay: 0.1s;
}
.navbar-opened .navbar__buttons .slide-in-nav:nth-of-type(3) {
  animation-delay: 0.2s;
}
.navbar-opened .navbar__buttons .slide-in-nav:nth-of-type(4) {
  animation-delay: 0.4s;
}
@keyframes fall-down {
  0% {
    transform: translateY(-100vh) rotateZ(0deg);
    -webkit-transform: translateY(-100vh) rotateZ(0deg);
    -moz-transform: translateY(-100vh) rotateZ(0deg);
    -ms-transform: translateY(-100vh) rotateZ(0deg);
    -o-transform: translateY(-100vh) rotateZ(0deg);
  }
  50% {
    transform: translateY(0) rotateZ(360deg);
    -webkit-transform: translateY(0) rotateZ(360deg);
    -moz-transform: translateY(0) rotateZ(360deg);
    -ms-transform: translateY(0) rotateZ(360deg);
    -o-transform: translateY(0) rotateZ(360deg);
  }
  70% {
    transform: translateY(-10vh) rotateZ(120deg);
    -webkit-transform: translateY(-10vh) rotateZ(120deg);
    -moz-transform: translateY(-10vh) rotateZ(120deg);
    -ms-transform: translateY(-10vh) rotateZ(120deg);
    -o-transform: translateY(-10vh) rotateZ(120deg);
  }
  100% {
    transform: translateY(0%) rotateZ(0deg);
    -webkit-transform: translateY(0%) rotateZ(0deg);
    -moz-transform: translateY(0%) rotateZ(0deg);
    -ms-transform: translateY(0%) rotateZ(0deg);
    -o-transform: translateY(0%) rotateZ(0deg);
  }
}
@keyframes gradient-flow {
  0% {
    background: -moz-linear-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
    background: -webkit-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
    background: -o-linear-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
    background: -ms-linear-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
    background: linear-gradient(-130deg, transparent 0%, transparent 60%, transparent 100%);
  }
  6% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 3%, transparent 100%);
  }
  12% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 6%, transparent 100%);
  }
  18% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 9%, transparent 100%);
  }
  24% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 12%, transparent 100%);
  }
  30% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 15%, transparent 100%);
  }
  36% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 18%, transparent 100%);
  }
  42% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 21%, transparent 100%);
  }
  48% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 24%, transparent 100%);
  }
  54% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 27%, transparent 100%);
  }
  60% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 30%, transparent 100%);
  }
  66% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 33%, transparent 100%);
  }
  72% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 36%, transparent 100%);
  }
  78% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 39%, transparent 100%);
  }
  80% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 42%, transparent 100%);
  }
  84% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 45%, transparent 100%);
  }
  88% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 48%, transparent 100%);
  }
  92% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 52%, transparent 100%);
  }
  96% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 56%, transparent 100%);
  }
  100% {
    background: -moz-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
    background: -webkit-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
    background: -webkit-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
    background: -o-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
    background: -ms-linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
    background: linear-gradient(-130deg, #464646 0%, rgba(70, 70, 70, 0.7) 60%, transparent 100%);
  }
}
@keyframes slide-in-nav {
  0% {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    opacity: 1;
  }
}
