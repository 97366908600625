.product-count {
  top: 0;
  position: relative;
  background-image: url("../../../../Assets/bg_ok.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  z-index: 300;
  padding: 4rem 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.product-count .absolute-spinner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
}
.product-count .page-wrapper {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 !important;
}
.product-count__header {
  background-color: #ffffff;
  padding: 4rem;
  color: #666666;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.product-count__header--title {
  margin: 0;
}
.product-count__header--close {
  right: 4rem !important;
  top: 4rem !important;
}
.product-count__body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 0 4rem 4rem 4rem;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.product-count__body .product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #99999934;
  padding-bottom: 0.2rem;
}
.product-count__body .product__name {
  font-size: 1.4rem;
}
.product-count__body .product__count {
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .product-count__body .product {
    width: 100%;
  }
}
