.order-editor-view .order-editor__title {
  margin-top: 0;
}
.order-editor-view .order-editor__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.order-editor-view .order-editor__form .wrapper {
  margin-bottom: 2rem;
}
.order-editor-view .order-editor__form .half-sized-input {
  max-width: 48%;
}
.order-editor-view .order-editor__form .button-wrapper .tiny-spinner {
  min-width: 4rem;
}
