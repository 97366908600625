.drag-placeholder-image {
  max-width: 40rem;
  height: auto;
  opacity: 1;
  background-color: white;
}
.unit-order-container {
  width: 100%;
  margin-top: -0.6rem;
  display: block;
  -webkit-box-shadow: 0 0.2rem 0.5rem 0 #99999934;
  -moz-box-shadow: 0 0.2rem 0.5rem 0 #99999934;
  box-shadow: 0 0.2rem 0.5rem 0 #99999934;
}
.unit-order-container .unit-order {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.7rem 0;
  border-bottom: 0.1rem solid #99999934;
  background-color: white;
  position: relative;
  z-index: 2;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.unit-order-container .unit-order:first-of-type {
  margin-top: 0.75rem;
}
.unit-order-container .unit-order:last-of-type {
  margin-bottom: 2rem;
}
.unit-order-container .unit-order__status {
  display: inline-flex;
  padding-left: 1.5rem;
  width: 5.3rem;
  max-width: 5.3rem;
  border-right: 0.1rem solid #99999934;
}
.unit-order-container .unit-order__status--counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.unit-order-container .unit-order__status--counter--number {
  padding-bottom: 0.5rem;
}
.unit-order-container .unit-order__status--counter--status-dot.order_created {
  background-color: #7de394;
}
.unit-order-container .unit-order__status--arrow-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0.75rem;
}
.unit-order-container .unit-order__status--arrow-container .up-arrow {
  transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: translateX(-0.1rem) rotateZ(-180deg);
  -webkit-transform: translateX(-0.1rem) rotateZ(-180deg);
  -moz-transform: translateX(-0.1rem) rotateZ(-180deg);
  -ms-transform: translateX(-0.1rem) rotateZ(-180deg);
  -o-transform: translateX(-0.1rem) rotateZ(-180deg);
}
.unit-order-container .unit-order__status--arrow-container .down-arrow,
.unit-order-container .unit-order__status--arrow-container .up-arrow {
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  color: #777777;
  cursor: pointer;
}
.unit-order-container .unit-order__status--arrow-container .down-arrow::before,
.unit-order-container .unit-order__status--arrow-container .up-arrow::before {
  display: block;
  margin: 0.1rem 0;
}
.unit-order-container .unit-order__status--arrow-container .down-arrow:hover,
.unit-order-container .unit-order__status--arrow-container .up-arrow:hover {
  color: #fb3350;
}
.unit-order-container .unit-order__data {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 35rem;
  max-width: 35rem;
  border-right: 0.1rem solid #99999934;
  padding: 0 1rem;
}
.unit-order-container .unit-order__data--address {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
}
.unit-order-container .unit-order__data--comment {
  width: 100%;
  font-size: 1rem;
  color: #777777;
}
.unit-order-container .unit-order__payment {
  border-right: 0.1rem solid #99999934;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 1rem;
  width: 15rem;
  max-width: 15rem;
  min-height: 3.7rem;
}
.unit-order-container .unit-order__payment--method {
  font-weight: 600;
  text-align: right;
  font-size: 1.2rem;
}
.unit-order-container .unit-order__payment--time {
  text-align: right;
}
.unit-order-container .unit-order__payment--time--warning {
  padding: 0.3rem 0.5rem;
  background-color: #fb3350;
  color: white;
  font-weight: 600;
}
.unit-order-container .unit-order__menu {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  max-width: 4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.unit-order-container .not-draggable-order {
  cursor: default;
}
.unit-order-container .not-draggable-order .unit-order__status--arrow-container .up-arrow,
.unit-order-container .not-draggable-order .unit-order__status--arrow-container .down-arrow {
  cursor: not-allowed;
  color: rgba(102, 102, 102, 0.20392157);
}
.unit-order-container .not-draggable-order .unit-order__status--arrow-container .up-arrow:hover,
.unit-order-container .not-draggable-order .unit-order__status--arrow-container .down-arrow:hover {
  color: rgba(102, 102, 102, 0.20392157);
}
.unit-order-container .unit-order-delivered .unit-order__data--address {
  color: #666;
}
.unit-order-container .unit-order-delivered .unit-order__payment--method {
  color: #666;
}
.unit-order-container .unit-order-delivered .unit-order__payment--time--warning {
  background-color: #99999934;
  color: #777777;
}
.unit-order-container .animate-order-row {
  opacity: 0;
  -webkit-animation: swing-item 1s cubic-bezier(0.36, -0.64, 0.34, 1.76) 0.1s forwards;
  -moz-animation: swing-item 1s cubic-bezier(0.36, -0.64, 0.34, 1.76) 0.1s forwards;
  -ms-animation: swing-item 1s cubic-bezier(0.36, -0.64, 0.34, 1.76) 0.1s forwards;
  -o-animation: swing-item 1s cubic-bezier(0.36, -0.64, 0.34, 1.76) 0.1s forwards;
  animation: swing-item 1s cubic-bezier(0.36, -0.64, 0.34, 1.76) 0.1s forwards;
}
.unit-order-container .currently-dragging {
  opacity: 0.4 !important;
  background-color: white;
}
.unit-order-container .drop-target-line {
  display: block;
  width: 100%;
  height: 1rem;
  z-index: 1;
  background-color: #fb3350;
}
.unit-order-container .disabled-unit-order {
  position: relative;
}
.unit-order-container .disabled-unit-order::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
}
@keyframes swing-item {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
  }
  30% {
    opacity: 0.5;
    transform: rotateX(-70deg);
    -webkit-transform: rotateX(-70deg);
    -moz-transform: rotateX(-70deg);
    -ms-transform: rotateX(-70deg);
    -o-transform: rotateX(-70deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
  }
}
