.common-input {
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  padding: 1rem 2rem;
  border: none;
  border-bottom: 1px solid #666666;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #f2f2f2;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.common-input input:-webkit-autofill,
.common-input input:-webkit-autofill:hover,
.common-input input:-webkit-autofill:focus,
.common-input textarea:-webkit-autofill,
.common-input textarea:-webkit-autofill:hover,
.common-input textarea:-webkit-autofill:focus,
.common-input select:-webkit-autofill,
.common-input select:-webkit-autofill:hover,
.common-input select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  transition: none;
}
.common-input:focus {
  border: none;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: 0 0 1px 1px #a93e73;
  -moz-box-shadow: 0 0 1px 1px #a93e73;
  box-shadow: 0 0 1px 1px #a93e73;
}
.input-label {
  font-weight: 400;
  font-size: 12px;
  color: #999;
}
.xa-button {
  background-color: #a93e73;
  color: white;
  font-weight: 600;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 1rem 2rem;
}
.xa-button .icon {
  margin-left: 1rem;
}
.xa-button:hover {
  background-color: #bb4680;
}
.xa-button:disabled {
  background-color: #acacac;
}
.xa-button:disabled:hover {
  background-color: #acacac;
}
.xa-button__alt-color {
  background-color: #ffffff;
  color: #a93e73;
  -webkit-box-shadow: inset 0 0 1px 1px #a93e73;
  -moz-box-shadow: inset 0 0 1px 1px #a93e73;
  box-shadow: inset 0 0 1px 1px #a93e73;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.xa-button__alt-color:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.xa-button__alt-color:disabled {
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 0 1px 1px #999;
  -moz-box-shadow: inset 0 0 1px 1px #999;
  box-shadow: inset 0 0 1px 1px #999;
  color: #999;
}
.xa-button__alt-color:disabled:hover {
  background-color: #ffffff;
}
.xa-button__text-type {
  background-color: #ffffff;
  color: #a93e73;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.xa-button__text-type:hover {
  background-color: #f2f2f2;
  color: #a93e73;
}
.xa-button__text-type:disabled {
  background-color: #ffffff;
  color: #999;
}
.xa-button__text-type:disabled:hover {
  background-color: #ffffff;
}
.select-input {
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.select-input .__control {
  font-size: 1.2rem;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 0.1rem solid #666666;
  background-color: #f2f2f2;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.select-input .__control .__value-container {
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.select-input .__control .__value-container .__single-value {
  color: black;
}
.select-input .__control .__multi-value__label {
  background-color: #a93e73;
  color: #ffffff;
}
.select-input .__control .__multi-value__remove {
  background-color: #a93e73;
  color: #ffffff;
  cursor: pointer;
}
.select-input .__control .__multi-value__remove:hover {
  background-color: #bb4680;
}
.select-input .__control:hover {
  border-bottom: 0.1rem solid transparent;
  -webkit-box-shadow: 0 0 1px 1px #a93e73 !important;
  -moz-box-shadow: 0 0 1px 1px #a93e73 !important;
  box-shadow: 0 0 1px 1px #a93e73 !important;
}
.select-input .__indicator {
  color: #999;
}
.select-input .__indicator-separator {
  opacity: 1;
}
.select-input .__indicator:hover {
  color: #a93e73;
}
.select-input .__dropdown-indicator {
  color: #a93e73;
}
.select-input .__menu {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.select-input .__menu .__option {
  color: black;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 1.2rem;
  cursor: pointer;
}
.select-input .__menu .__option--is-focused {
  background-color: #f2f2f2;
}
.select-input .__menu .__option--is-focused:hover {
  background-color: #f2f2f2;
}
.select-input .__menu .__option--is-selected {
  color: white;
  background-color: #a93e73;
}
.select-input .__menu .__option--is-selected:hover {
  color: white;
  background-color: #a93e73;
}
.disabled-select-wrapper {
  cursor: not-allowed;
}
.disabled-select-wrapper .select-input .__control {
  background-color: #99999934;
  color: white !important;
}
.disabled-select-wrapper .select-input .__control .__indicator {
  display: none;
}
.disabled-select-wrapper .select-input .__control .__indicator-separator {
  display: none;
}
.disabled-select-wrapper .select-input .__control .__value-container .__single-value {
  color: black;
}
.wrapper-sm {
  min-height: 2rem;
  text-align: left;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 100%;
  font-size: 1rem;
  position: relative;
}
.wrapper .input-label {
  color: #666666;
}
.radio-container {
  font-size: 1rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  background-color: #ffffff;
  padding: 0.5rem 1rem 0.5rem 3rem;
  min-height: 5.2rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-bottom: 2px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  font-weight: 300;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  justify-content: flex-start;
  text-transform: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.radio-container .radio-count {
  margin-left: 0.3rem;
  color: #666666;
}
.radio-container .text {
  display: none;
}
.radio-container .check-mark {
  position: absolute;
  top: 2rem;
  left: 1rem;
  height: 1.2rem;
  width: 1.2rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}
.radio-container:hover {
  background-color: #f2f2f2;
}
.active-radio .radio-container {
  -webkit-box-shadow: inset 2px -7px 23px -12px rgba(0, 0, 0, 0.22) !important;
  -moz-box-shadow: inset 2px -7px 23px -12px rgba(0, 0, 0, 0.22) !important;
  box-shadow: inset 2px -7px 23px -12px rgba(0, 0, 0, 0.22) !important;
  color: #a93e73;
  border-bottom: 2px solid #a93e73;
  font-weight: 600;
  cursor: default;
}
.disabled-radio-input .radio-container {
  cursor: not-allowed;
  background-color: #cccccc !important;
}
.disabled-radio-input .radio-container .check-mark {
  background-color: #777777 !important;
  border: 0.1rem solid #777777 !important;
}
.radio__all .radio-container .check-mark {
  border-right-color: #ffa650;
  border-top-color: #29b9ff;
  border-bottom-color: #d529ff;
  border-left-color: #d529ff;
  border-width: 0.6rem;
  border-style: solid;
  height: 0px;
  width: 0px;
}
.radio__all--other .radio-container .check-mark {
  height: 0px;
  width: 0px;
  border-width: 0.6rem;
  border-style: solid;
  border-right-color: #7de394;
  border-top-color: #ffa650;
  border-bottom-color: #fb3350;
  border-left-color: #fb3350;
}
.radio__on-delivery .check-mark {
  background-color: #29b9ff;
}
.radio__delivered .check-mark {
  background-color: #d529ff;
}
.radio__not-occupied .check-mark {
  background-color: #ffa650;
}
.radio__running-late .check-mark {
  background-color: #fb3350;
}
.radio__done .check-mark {
  background-color: #7de394;
}
.switch .switch {
  background-color: #acacac;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
}
.switch .switch:before {
  background-color: #ffffff;
}
.switch .switch-input:checked + .switch {
  background-color: #a93e73;
}
.switch .switch-input:checked + .switch:before {
  background-color: #ffffff;
}
.disabled-switch-wrapper .switch {
  background-color: #acacac !important;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  cursor: not-allowed !important;
}
.checkbox-container {
  color: black;
}
.checkbox-container .check-mark {
  background-color: white;
  border: 0.2rem solid #999;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.checkbox-container:hover .checkbox-input ~ .check-mark {
  background-color: #a93e73;
  border: 0.2rem solid #a93e73;
}
.checkbox-container .checkbox-input:checked ~ .check-mark {
  background-color: #a93e73;
  border: 0.2rem solid #a93e73;
}
.checkbox-container .checkbox-input:checked ~ .check-mark::after {
  border-color: white;
}
.checkbox-container .check-mark:after {
  border-color: #a93e73;
  top: 0.25rem;
  left: 0.55rem;
}
.wrapper .datepicker-icon {
  color: #a93e73;
  top: unset;
  bottom: 1.3rem;
  right: 2rem;
}
.wrapper .datepicker-icon::before {
  font-size: 1.6rem;
}
.react-datepicker__input-container {
  border: none;
  border-bottom: 0.1rem solid #666666;
  background-color: #f2f2f2;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.react-datepicker__input-container .date-picker {
  padding: 1.25rem 1.5rem;
  color: black;
}
.react-datepicker-popper .react-datepicker__current-month {
  text-transform: capitalize;
}
.react-datepicker-popper .react-datepicker__navigation--previous:hover {
  border-right-color: #a93e73;
}
.react-datepicker-popper .react-datepicker__navigation--next:hover {
  border-left-color: #a93e73;
}
.react-datepicker-popper .react-datepicker__year-read-view--down-arrow:hover {
  border-top-color: #a93e73;
}
.react-datepicker-popper .react-datepicker__year-option {
  background-color: #ffffff;
  margin-bottom: 0;
  font-weight: 400;
  border-bottom: 0.1rem solid #99999934;
}
.react-datepicker-popper .react-datepicker__year-option:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.react-datepicker-popper .react-datepicker__year-option--selected_year {
  background-color: #a93e73;
  color: #ffffff;
  font-weight: 600;
}
.react-datepicker-popper .react-datepicker__year-option--selected_year:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.react-datepicker-popper .react-datepicker__day:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.react-datepicker-popper .react-datepicker__day--selected {
  background-color: #a93e73;
  color: #ffffff !important;
  font-weight: 600;
}
.react-datepicker-popper .react-datepicker__day--today {
  color: #a93e73;
}
.react-datepicker-popper .react-datepicker__time-list-item:hover {
  background-color: #a93e73 !important;
  color: #ffffff;
}
.react-datepicker-popper .react-datepicker__time-list-item--selected {
  background-color: #a93e73 !important;
  color: #ffffff;
}
