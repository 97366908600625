.cube-container {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.cube-container .cube {
  margin: 0.1rem 0.1rem 0.1rem 0;
  background-color: #999;
}
.cube-container:hover {
  background-color: #666666;
}
.cube-container:hover .cube {
  background-color: white;
}
