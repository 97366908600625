.require-pin {
  background-color: white;
  width: 400px;
  margin: auto;
}
.require-pin__title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4rem;
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #99999934;
  position: relative;
}
.require-pin__title .close {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.require-pin__title .close:hover {
  background-color: #f2f2f2;
}
.require-pin__image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.require-pin__image .locker-image {
  max-width: 70px;
  height: auto;
}
.require-pin__form {
  display: block;
  max-width: 70px;
  margin: 2rem auto 0 auto;
}
.require-pin__invalid {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}
.require-pin__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.require-pin__button .button-wrapper .xa-button {
  width: 100%;
  height: 50px;
}
