.summary-view__body-data {
  width: calc(100% - 27rem);
}
.summary-view__body-data .summary-form {
  width: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.summary-view__body-data .summary-form .flex-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.summary-view__body-data .summary-form .flex-div .button {
  margin-right: 1rem;
}
.summary-view__body-data .summary-form .wrapper {
  max-width: 48%;
}
.summary-view__body-data .summary-form .button-wrapper {
  padding-top: 2rem;
}
