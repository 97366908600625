.delivery-view__manual-dispatch .button-wrapper {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.3rem;
}
.delivery-view__manual-dispatch .button-wrapper .xa-button {
  margin-right: 1px;
}
.delivery-view__manual-dispatch .button-wrapper .xa-button .dispatch-icon {
  font-weight: 400;
  font-size: 1.2rem;
}
.delivery-view__manual-dispatch .button-wrapper .schema-date-container {
  text-align: right;
  margin-left: 1rem;
  font-size: 1.2rem;
  color: #666666;
}
.delivery-view__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivery-view__filter .text-input {
  border-bottom: none;
  color: #495057;
  border-bottom: 1px solid #ced4da;
  background-color: #e0e7e9;
  height: 40px;
  margin-bottom: 1rem;
  -webkit-transition: all 0.3s linear !important;
  -moz-transition: all 0.3s linear !important;
  -ms-transition: all 0.3s linear !important;
  -o-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}
.delivery-view__filter .text-input:focus {
  border-top: 2px solid #f2f2f2 !important;
  height: 60px;
  outline: none !important;
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0 5px 6px 2px rgba(30, 30, 30, 0.2);
}
.delivery-view__filter--counter {
  width: 30%;
}
.delivery-view__filter--text {
  width: 69%;
}
