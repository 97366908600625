.courier-infobox__name {
  width: 100%;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.courier-infobox__name--box {
  padding: 0.3rem 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  color: #ffffff;
}
.courier-infobox__data {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.courier-infobox__data--block {
  width: 50%;
  display: block;
  margin-bottom: 1.5rem;
  padding-right: 1.5rem;
}
.courier-infobox__data--block .label,
.courier-infobox__data--block .value {
  width: 100%;
  margin-bottom: 0.5rem;
}
.courier-infobox__data--block .value {
  font-weight: 600;
}
.courier-infobox__data--block .time-value-warning {
  background-color: #a93e73;
  color: #ffffff;
  padding: 0.3rem 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
