.orders-view {
  width: 100%;
}
.orders-view__filter-view {
  padding: 0 1.3rem 0 1.3rem;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
.orders-view__filter-view .wrapper-sm {
  max-width: 22%;
}
.orders-view__text-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem dashed #99999934;
}
.orders-view__text-filter .text-input {
  border-bottom: none;
  color: #495057;
  border-bottom: 1px solid #ced4da;
  background-color: #e0e7e9;
  height: 40px;
  -webkit-transition: all 0.3s linear !important;
  -moz-transition: all 0.3s linear !important;
  -ms-transition: all 0.3s linear !important;
  -o-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}
.orders-view__text-filter .text-input:focus {
  border-top: 2px solid #f2f2f2 !important;
  height: 60px;
  outline: none !important;
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0 5px 6px 2px rgba(30, 30, 30, 0.2);
}
.orders-view__text-filter--counter {
  width: 30%;
}
.orders-view__text-filter--text {
  width: 69%;
}
.orders-view__assign-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1.3rem;
  background-color: #ffffff;
}
.orders-view__list-container {
  width: 100%;
  margin: 0 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 4rem;
  height: calc(100vh - 18.2rem);
  position: relative;
}
.orders-view__list-container .order {
  width: 100%;
  display: flex;
  border-bottom: 0.1rem solid #99999934;
  justify-content: flex-start;
  background-color: #ffffff;
  margin-bottom: 0.3rem;
  align-items: stretch;
  cursor: pointer;
}
.orders-view__list-container .order__checkbox {
  padding: 1rem;
  border-right: 0.1rem solid #99999934;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 4.1rem;
  width: 100%;
  position: relative;
}
.orders-view__list-container .order__checkbox .wrapper-sm .checkbox-container {
  height: 2rem;
  padding-left: 2rem;
  display: flex;
}
.orders-view__list-container .order__checkbox--overlay {
  position: absolute;
  background-color: transparent;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.orders-view__list-container .order__status {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-right: 0.1rem solid #99999934;
  max-width: 4rem;
  width: 100%;
  padding: 1rem 0;
}
.orders-view__list-container .order__status--counter {
  padding-bottom: 0.5rem;
}
.orders-view__list-container .order__data {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  max-width: 35rem;
  width: 100%;
  border-right: 0.1rem solid #99999934;
}
.orders-view__list-container .order__data--address {
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
}
.orders-view__list-container .order__data--address-notes {
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
}
.orders-view__list-container .order__data--name {
  width: 100%;
  color: #777777;
  font-size: 1rem;
}
.orders-view__list-container .order__payment {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 12.9rem;
  padding: 0.5rem 0 0.5rem 1rem;
  width: 100%;
}
.orders-view__list-container .order__payment--method {
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  text-align: right;
}
.orders-view__list-container .order__payment--timer--warning {
  background-color: #fb3350;
  color: white;
  padding: 0.3rem 0.5rem;
  font-weight: 600;
}
.orders-view__list-container .order__dots {
  max-width: 4rem;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.orders-view__more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}
