.confirm-modal-view__text {
  display: block;
  width: 100%;
  padding-bottom: 1rem;
}
.confirm-modal-view__button-wrapper {
  display: flex;
  justify-content: center;
}
.confirm-modal-view__button-wrapper .xa-button:first-of-type {
  margin-right: 2rem;
}
