/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/01/2021 15:32
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.eot");
    src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
        url("./Flaticon.woff2") format("woff2"), url("./Flaticon.woff") format("woff"),
        url("./Flaticon.ttf") format("truetype"), url("./Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 1.4rem;
    font-style: normal;
}

.flaticon-down-arrow:before {
    content: "\f100";
}
.flaticon-pencil:before {
    content: "\f101";
}
.flaticon-ellipsis:before {
    content: "\f102";
}
.flaticon-more:before {
    content: "\f103";
}
.flaticon-information:before {
    content: "\f104";
}
.flaticon-calendar:before {
    content: "\f105";
}
.flaticon-settings:before {
    content: "\f106";
}
.flaticon-printer:before {
    content: "\f107";
}
.flaticon-switch:before {
    content: "\f108";
}
.flaticon-floppy-disk:before {
    content: "\f109";
}
.flaticon-connection:before {
    content: "\f10a";
}
