.summary-view {
  top: 0;
  position: relative;
  background-image: url("../../../../Assets/bg_ok.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  z-index: 300;
  padding: 4rem 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.summary-view .absolute-spinner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
}
.summary-view .page-wrapper {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 !important;
}
.summary-view__header {
  background-color: #ffffff;
  padding: 4rem;
  color: #666666;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.summary-view__header--title {
  margin: 0;
}
.summary-view__header--close {
  right: 4rem !important;
  top: 4rem !important;
}
.summary-view__body {
  display: flex;
  background-color: #ffffff;
  padding: 0 4rem 4rem 4rem;
  margin-bottom: 2rem;
  justify-content: flex-start;
}
.summary-view__footer {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
}
