.archived-deliveries-view .archive-dispatch {
  display: flex;
  align-items: center;
}
.archived-deliveries-view .archive-dispatch .text {
  margin-left: 2rem;
  font-size: 1.4rem;
}
.archived-deliveries-view .delivery-view__list-container .delivery-unit__data {
  width: 33rem;
}
.archived-deliveries-view .delivery-view__list-container .delivery-unit__data--name {
  padding-bottom: 0;
}
.archived-deliveries-view .delivery-view__list-container .delivery-unit__price {
  border-left: 1px solid #99999934;
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 18rem;
  font-size: 1.2rem;
}
.archived-deliveries-view .delivery-view__list-container .delivery-unit__price--label {
  font-weight: 600;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element {
  cursor: default;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element .unit-order__status {
  justify-content: center;
  align-items: center;
  padding-left: 0;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element .unit-order__data {
  max-width: 39rem;
  width: 39rem;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element .unit-order__data--price {
  width: 100%;
  font-size: 1.1rem;
  font-style: italic;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element .unit-order__data--price-label {
  font-style: normal;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .archived-order-element .unit-order__payment {
  border-right: none;
}
.archived-deliveries-view .delivery-view__list-container .archived-order-container .failed-order {
  background-color: rgba(140, 62, 62, 0.17);
}
