::-webkit-scrollbar {
  width: 0.8rem;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: #666666;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #fb3350;
}
html {
  height: 100%;
  box-sizing: border-box;
  font-size: 10px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  overflow: hidden;
}
body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Open Sans";
  overflow: hidden;
}
#root {
  min-height: 100vh;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}
/* Built-in css created arrow */
/* Use the border-color property to color it */
.arrow {
  border: solid #777777;
  border-width: 0 0.5rem 0.5rem 0;
  display: inline-block;
  padding: 0.5rem;
  cursor: pointer;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.arrow:hover {
  border-color: transparent #cccccc #cccccc transparent;
}
.circle {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #e7ecef;
}
.cube {
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  background-color: white;
  margin: 0.3rem 0;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
}
.threads {
  cursor: pointer;
}
.threads .thread {
  width: 2rem;
  height: 0.3rem;
  display: block;
  margin: 0.3rem 0;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #a93e73;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.threads:hover .thread {
  background-color: #cccccc;
}
/* Built-in css created close element. Displays an X */
/* Use the background and border-color property to color it */
.exit {
  top: 0;
  right: 0;
  position: absolute;
  background-color: transparent;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  cursor: pointer;
  color: #cccccc;
}
.exit::after {
  content: "\d7";
  font-weight: bold;
  font-size: 2.6rem;
}
.exit:hover {
  color: #bb4680;
}
.no-data {
  padding: 2rem 4rem;
  position: relative;
  margin: 0 auto;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  color: #666666;
  font-style: italic;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  background-color: black;
  z-index: 1;
  font-weight: 600;
  color: #ffffff;
}
button {
  outline: none !important;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
button:focus {
  outline: none !important;
}
input {
  outline: none !important;
  -webkit-transition: border 0.3s linear;
  -moz-transition: border 0.3s linear;
  -ms-transition: border 0.3s linear;
  -o-transition: border 0.3s linear;
  transition: border 0.3s linear;
}
input:focus {
  outline: none !important;
}
a {
  text-decoration: none;
  outline: none !important;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  color: #999;
}
a:hover {
  text-decoration: none;
  outline: none !important;
  color: #a6a6a6;
}
a:focus {
  outline: none !important;
}
a:visited {
  outline: none !important;
}
.error-container__oneline-text {
  color: #a93e73;
  border: 0.1rem solid #bb4680;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  margin: 1rem auto;
  text-align: center;
  padding: 0.5rem 1rem;
}
.error-container__button {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0.8rem auto 0 auto;
}
.error-container__button button {
  cursor: pointer;
  margin: 0 auto;
  background-color: #a93e73;
  padding: 0.5rem 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
}
.error-container__button button:hover {
  background-color: #bb4680;
}
.cube-container:hover {
  background-color: #f2f2f2;
}
.cube-container:hover .cube {
  background-color: #999;
}
.context-menu-dropdown {
  background-color: white;
  -webkit-box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.3);
  margin-right: 1rem;
  position: absolute;
  z-index: 5;
}
.context-menu-dropdown .menu-item {
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
}
.context-menu-dropdown .menu-item:hover {
  background-color: #a93e73;
  color: white;
  font-weight: 600;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator {
  width: 100%;
  margin: 1rem 0;
  height: 0.2rem;
  background-color: #99999934;
}
.page-wrapper {
  min-height: 100vh;
}
.padded-spinner {
  padding-top: 10rem;
}
.done,
.assigned_to_courier {
  background-color: #7de394;
}
.go_back {
  background-color: #d529ff;
}
.late {
  background-color: #fb3350;
}
.on_road,
.courier_started {
  background-color: #29b9ff;
}
.not-occupied,
.in_progress {
  background-color: #ffa650;
}
.created {
  background-color: #ffa650;
}
.order_accepted {
  background-color: #e7ecef;
}
.order_delivered {
  background-color: #d529ff;
}
.archived {
  background-color: #a93e73;
}
.backdrop {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.modal-wrapper {
  width: 60rem;
  margin-left: calc((100vw - 60rem) / 2);
  margin-right: calc((100vw - 60rem) / 2);
}
.modal-wrapper .react-modal {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.modal-wrapper .react-modal__title {
  margin-bottom: 1.5rem;
}
.modal-wrapper .react-modal__close {
  background-color: transparent;
  color: #cccccc;
  cursor: pointer;
  display: block;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.modal-wrapper .react-modal__close::after {
  position: absolute;
  content: "\d7";
  font-weight: bold;
  top: -1rem;
  right: 0.5rem;
  font-size: 3.6rem;
}
.modal-wrapper .react-modal__close:hover {
  color: #bb4680;
}
.page-wrapper {
  padding-top: 8rem;
  padding-bottom: 6rem;
  padding-left: 6rem;
  padding-right: 6rem;
}
@media screen and (max-width: 1200px) {
  .page-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .page-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
