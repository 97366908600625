@media print {
  * {
    visibility: hidden;
  }
  #summaryprint,
  #summaryprint * {
    visibility: visible;
    overflow: visible;
    height: auto;
  }
  #summaryprint {
    position: absolute;
    top: 7rem;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: unset !important;
  }
  #summaryprint .icon {
    display: none !important;
  }
  #summaryprint .date {
    display: block;
  }
}
.summary-data {
  padding-bottom: 1rem;
  margin-left: 4rem;
  margin-bottom: 4rem;
}
.summary-data:first-of-type {
  border-bottom: 0.3rem solid #99999934;
}
.summary-data__title {
  color: #666666;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary-data__title .xa-button {
  display: flex;
  align-items: center;
}
.summary-data__title .xa-button .icon::before {
  font-size: 2rem;
  font-weight: 400;
}
.summary-data__title .date {
  display: none;
  font-weight: 400;
  font-size: 1.4rem;
}
.summary-data__list {
  display: block;
}
.summary-data__list--element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  border-bottom: 0.1rem solid #99999934;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  color: #666666;
}
.summary-data__list--element:last-of-type {
  border-bottom: none;
}
.summary-data__list--element .sum-label {
  font-weight: 600;
}
.summary-data__list--element.summary-strong {
  border-bottom: none;
  font-size: 2rem;
}
.summary-data__list--element.add-space {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.summary-data__list--element .sum-icon {
  margin-left: 0.5rem;
  cursor: pointer;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.summary-data__list--element .sum-icon::before {
  font-size: 1.4rem;
}
.summary-data__list--element .sum-icon:hover {
  color: #fb3350;
}
.summary-data__list .center-form {
  width: 100%;
  position: relative;
  border-bottom: 0.1rem solid #99999934;
  margin-bottom: 1.5rem;
}
.summary-data__list .center-form .sum-icon {
  top: -0.75rem;
  right: 0.75rem;
  cursor: pointer;
  z-index: 1;
}
.summary-data__list .center-form__bottom-container {
  padding: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
