.delivery-view {
  position: relative;
  height: calc(100vh - 5rem);
  overflow: hidden;
}
.delivery-view__filter-view {
  padding: 0 1.3rem 0 1.3rem;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
.delivery-view__filter-view .wrapper-sm {
  max-width: 22%;
}
.delivery-view .delivery-view-loader {
  top: 6.3rem;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10rem 0;
}
.delivery-view .delivery-view-loader .spinner-child {
  font-size: 1.6rem;
}
.delivery-view__list-container {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 16rem);
  padding-bottom: 4rem;
}
.delivery-view .pos-authenticated-list {
  height: calc(100vh - 13rem);
}
