.assign-courier-view .assign-courier__title {
  margin-top: 0;
  font-size: 1.4rem;
  font-weight: 400;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 1rem;
  margin-right: 3rem;
  margin-bottom: 0.5rem;
}
.assign-courier-view .assign-courier__list {
  max-height: 45rem;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}
.assign-courier-view .assign-courier__list--element {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  border-bottom: 0.1rem solid #99999934;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column--count {
  font-weight: 600;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column--radio-button .radio-container {
  border-left: none;
  border-bottom: none;
  min-height: 4rem;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column--radio-button .radio-container .check-mark {
  top: 1.5rem;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column--radio-button .radio-container .text {
  font-size: 1.2rem;
  color: black;
  display: block;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column--radio-button .radio-container .check-mark {
  border: 0.1rem solid black;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column .active-radio .radio-container {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.assign-courier-view .assign-courier__list--element .assign-courier-column .active-radio .radio-container .check-mark {
  background-color: #a93e73;
  border-color: #a93e73;
}
.assign-courier-view .tiny-spinner {
  min-width: 9rem;
}
