.couriers-view {
  width: 100%;
  position: relative;
}
.couriers-view__title {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 0;
  border-bottom: 1px solid #f2f2f2;
  margin-right: 30px;
  padding-bottom: 1rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.couriers-view .absolute-spinner {
  top: -3rem;
}
.couriers-view__pin {
  display: block;
  text-align: center;
  width: 100%;
  padding: 0 2rem 1rem 2rem;
  font-size: 1.4rem;
}
.couriers-view__list-container {
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.couriers-view__list-container .courier {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 0.1rem solid #99999934;
  color: black;
}
.couriers-view__list-container .courier__header {
  border-top: 0.1rem solid #99999979;
  border-bottom: 0.1rem solid #99999979;
  padding: 0.5rem 0;
  font-weight: 600;
}
.couriers-view__list-container .courier .name {
  width: calc(100% - 33rem);
}
.couriers-view__list-container .courier .email {
  width: 13rem;
  padding: 0 0.5rem;
  text-align: left;
}
.couriers-view__list-container .courier .input {
  display: inline-flex;
  justify-content: space-between;
  width: 20rem;
}
.couriers-view__list-container .courier .input .wrapper {
  width: 5rem;
  margin-left: 1rem;
}
