@media print {
  * {
    visibility: hidden;
  }
  #printarea,
  #printarea * {
    visibility: visible;
    overflow: visible;
    height: auto;
  }
  #printarea {
    max-height: unset !important;
  }
}
.print-view {
  padding: 0 40px;
  max-height: 100vh;
  overflow-y: auto;
}
.print-view__title {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.print-view__data-block {
  position: relative;
  padding: 0;
  border-bottom: 2px solid #999999;
  display: flex;
  justify-content: flex-start;
}
.print-view__data-block:nth-of-type(2n) {
  background-color: #e6e6e6;
}
.print-view__data-block--col {
  font-size: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: 0.2rem solid #999999;
}
.print-view__data-block--col .price-sum {
  padding: 2px 4px;
}
.print-view__data-block--footer {
  background-color: white !important;
}
.print-view__data-block .sum-column {
  background-color: transparent;
  padding: 15px 20px 15px 20px;
}
.print-view__data-block .col-1 {
  min-width: 30px;
  max-width: 30px;
  text-align: left;
  padding-left: 0.3rem;
}
.print-view__data-block .col-2 {
  min-width: 120px;
  max-width: 120px;
}
.print-view__data-block .col-3 {
  min-width: 180px;
  max-width: 180px;
}
.print-view__data-block .col-4 {
  min-width: 100px;
  max-width: 100px;
}
.print-view__data-block .col-5 {
  min-width: 180px;
  max-width: 180px;
  word-break: break-all;
}
.print-view__data-block .col-6 {
  min-width: 82px;
  max-width: 82px;
}
.print-view__data-block .col-7 {
  min-width: 70px;
  border-right: none;
}
.print-view__data-block .col-8 {
  min-width: 70px;
  max-width: 70px;
}
.print-view__data-block .col-10 {
  min-width: 30px;
  max-width: 30px;
  font-weight: 600;
  word-break: break-all;
  justify-content: center;
}
.print-view .payment-section {
  flex-wrap: wrap;
  border-bottom: none;
  padding: 0;
}
.print-view .payment-section .sum-col {
  padding: 5px 10px;
  border-bottom: 2px solid #999;
}
.print-view .data-block-head {
  border: none;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0.2rem solid #999999;
}
.print-view .data-block-head .print-view__data-block--col {
  font-size: 14px;
}
