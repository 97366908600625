.notify-view {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  bottom: 10rem;
  padding: 1rem;
  width: 35rem;
  z-index: 30;
  top: 10rem;
  left: 0;
}
.notify-view__list {
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.notify-view__element {
  -webkit-animation: notifiy-slide 0.3s linear 0s 1 forwards;
  -moz-animation: notifiy-slide 0.3s linear 0s 1 forwards;
  -ms-animation: notifiy-slide 0.3s linear 0s 1 forwards;
  -o-animation: notifiy-slide 0.3s linear 0s 1 forwards;
  animation: notifiy-slide 0.3s linear 0s 1 forwards;
  background-color: #ffffff;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  margin-bottom: 0.8rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  pointer-events: all;
  padding: 0.4rem 5rem 0.4rem 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.notify-view__element .circle {
  margin-right: 1rem;
}
.notify-view__element--text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 1.2rem;
  padding: 1rem 0;
  color: black;
}
.notify-view__element .exit {
  right: 1rem;
  top: 0.5rem;
}
@keyframes notifiy-slide {
  0% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    opacity: 1;
  }
}
