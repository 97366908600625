.print-menu {
  padding: 2rem 4rem;
  font-size: 1.2rem;
  width: 100%;
}
.print-menu__courier {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  font-weight: 600;
}
.print-menu .table__row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 2px solid #999999;
  border-top: none;
}
.print-menu .table__row:nth-of-type(2n) {
  background-color: #e6e6e6;
}
.print-menu .table__col {
  display: block;
  padding: 0.2rem 1rem;
  min-height: 1.8rem;
}
.print-menu .table__head {
  font-weight: 600;
  border-top: 2px solid #999999;
  font-size: 1.4rem;
}
.print-menu .col-1 {
  width: 40%;
  border-right: 2px solid #999999;
}
.print-menu .col-2 {
  width: 40%;
  border-right: 2px solid #999999;
}
.print-menu .col-3 {
  width: 20%;
}
