.label-printing {
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
.label-printing__item {
  display: block;
  min-width: 179px;
  max-width: 179px;
  min-height: 86px;
  max-height: 86px;
  padding-left: 10px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}
.label-printing__item--name {
  text-align: center;
  padding-bottom: 0.5rem;
  font-size: 10px;
  font-weight: 600;
}
.label-printing__item--row {
  display: block;
  width: 100%;
  font-size: 9px;
}
.label-printing__item--row.tel-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
}
.label-printing__item--row.food {
  font-weight: 600;
}
.label-printing__item--row.address {
  min-height: 25px;
  max-height: 25px;
  width: 165px;
}
