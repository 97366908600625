.summary-sidebar {
  display: block;
  width: 27rem;
}
.summary-sidebar__tab {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  padding: 1.5rem 2rem;
  border-bottom: 0.1rem solid #99999934;
  border-right: 0.1rem solid #99999934;
  cursor: pointer;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0.5rem;
  -webkit-border-top-left-radius: 0.5rem;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0.5rem;
  -moz-border-radius-topleft: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.summary-sidebar__tab:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.summary-sidebar .unavailable-courier {
  background-color: rgba(170, 170, 170, 0.2);
}
.summary-sidebar .unavailable-courier:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.summary-sidebar .active-tab {
  background-color: #a93e73;
  color: #ffffff;
  font-weight: 600;
}
