.delivery-unit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  width: 100%;
  display: flex;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  align-items: stretch;
  justify-content: flex-start;
  background-color: #ffffff;
  -webkit-box-shadow: 0.1rem 0.3rem 0.8rem 0.3rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0.1rem 0.3rem 0.8rem 0.3rem rgba(0, 0, 0, 0.1);
  box-shadow: 0.1rem 0.3rem 0.8rem 0.3rem rgba(0, 0, 0, 0.1);
}
.delivery-unit__opener {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  border-right: 0.1rem solid #99999934;
}
.delivery-unit__opener--icon-container {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.delivery-unit__opener--icon-container .icon {
  -webkit-transition: border-color 0.2s linear;
  -moz-transition: border-color 0.2s linear;
  -ms-transition: border-color 0.2s linear;
  -o-transition: border-color 0.2s linear;
  transition: border-color 0.2s linear;
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  border-color: #999;
  margin-top: -0.3rem;
  border-width: 0 0.3rem 0.3rem 0;
  padding: 0.3rem;
}
.delivery-unit__opener--icon-container:hover {
  background-color: #f2f2f2;
}
.delivery-unit__opener--icon-container .spinner {
  top: -0.25rem;
  left: -0.25rem;
}
.delivery-unit__data {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 20rem;
  padding: 0.5rem 1rem;
}
.delivery-unit__data--name {
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delivery-unit__data--counters {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 600;
}
.delivery-unit__data--counters--leftover {
  color: black;
  display: inline-block;
}
.delivery-unit__data--counters--delivered {
  color: #777777;
  display: inline-block;
}
.delivery-unit__status {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 6rem;
  -webkit-box-shadow: 0.1rem 0 0 0 #99999934;
  -moz-box-shadow: 0.1rem 0 0 0 #99999934;
  box-shadow: 0.1rem 0 0 0 #99999934;
  padding-right: 0.5rem;
}
.delivery-unit__status--box {
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
}
.delivery-unit__status--box.created {
  color: white;
}
.delivery-unit__status--box.go_back {
  color: white;
}
.delivery-unit__delivery-time {
  padding: 0 1rem;
  width: 12.8rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.delivery-unit__delivery-time--text {
  font-size: 1.2rem;
  width: 100%;
}
.delivery-unit__delivery-time--mins {
  width: 100%;
  font-size: 1.2rem;
}
.delivery-unit__delivery-time--mins .minutes {
  font-weight: 600;
}
.delivery-unit__button {
  width: 13rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0.5rem;
}
.delivery-unit__menu {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
}
.delivery-unit-opened {
  margin-bottom: 0;
  -webkit-box-shadow: 0 0.2rem 0 0 #99999934;
  -moz-box-shadow: 0 0.2rem 0 0 #99999934;
  box-shadow: 0 0.2rem 0 0 #99999934;
  border-top: 0.1rem solid #99999934;
}
.delivery-unit-opened .delivery-unit__opener--icon-container {
  transform: rotateZ(-180deg);
  -webkit-transform: rotateZ(-180deg);
  -moz-transform: rotateZ(-180deg);
  -ms-transform: rotateZ(-180deg);
  -o-transform: rotateZ(-180deg);
}
.delivery-unit-disabled {
  position: relative;
}
.delivery-unit-disabled .delivery-unit__opener--icon-container {
  cursor: not-allowed;
}
.delivery-unit-disabled .delivery-unit__opener--icon-container:hover {
  background-color: transparent;
}
.delivery-unit-disabled .delivery-unit__opener--icon-container:hover .icon {
  color: #777777;
}
.delivery-unit-disabled .delivery-unit__menu .cube-container {
  cursor: not-allowed;
}
.delivery-unit-disabled .delivery-unit__menu .cube-container:hover {
  background-color: transparent;
}
.delivery-unit-disabled .delivery-unit__menu .cube-container:hover .cube {
  background-color: #777777;
}
.delivery-unit-disabled::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
}
