.spinner {
  z-index: 10000;
  width: 100%;
  text-align: center;
}
.spinner .spinner-child {
  font-size: 1.2rem;
  font-weight: 600;
  max-width: 30rem;
  margin: 0 auto;
  color: black;
}
.spinner .lds-dual-ring {
  display: inline-block;
  width: 4rem;
  height: 4rem;
}
.spinner .lds-dual-ring::after {
  content: " ";
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.1rem solid #a93e73;
  border-color: #a93e73 transparent #a93e73 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tiny-spinner {
  width: 100%;
}
.tiny-spinner .spinner-child {
  display: none;
}
.tiny-spinner .lds-dual-ring {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: -0.1rem;
  margin-bottom: -0.2rem;
}
.tiny-spinner .lds-dual-ring::after {
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.1rem;
}
.large-spinner .spinner-child {
  font-size: 2rem;
}
.large-spinner .lds-dual-ring {
  width: 12rem;
  height: 12rem;
}
.large-spinner .lds-dual-ring::after {
  width: 12rem;
  height: 12rem;
  border-width: 0.3rem;
}
.absolute-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.negated-spinner .spinner-child {
  color: white;
}
.negated-spinner .lds-dual-ring::after {
  border-color: white transparent white transparent;
}
