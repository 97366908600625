.map {
  font-size: 1rem;
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  margin-top: 5rem;
}
.map .map-view {
  position: absolute;
  width: 100%;
  height: 100%;
}
.map .map-view .marker-container {
  cursor: pointer;
}
.map .map-view .marker-container .restaurant-icon {
  background-image: url("../../../../Assets/Restaurant__sign.png") !important;
  background-size: contain;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .label {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.map .map-view .marker-container .restaurant-label {
  background-color: #fb3350;
  color: white;
  font-weight: 600;
}
.map .map-view .marker-container .late-sign {
  background-image: url("../../../../Assets/Late__sign.png");
  background-size: contain;
  height: 15px;
  width: 15px;
  position: absolute;
  left: 50%;
  top: 0.3rem;
}
.map .map-view .marker-container .checked-order-marker {
  border: 0.2rem solid black;
}
.map .map-view .marker-container .order_created-icon {
  background-image: url("../../../../Assets/FoodInitial__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .order_accepted-icon {
  background-image: url("../../../../Assets/FoodInitial__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .in_progress-icon {
  background-image: url("../../../../Assets/FoodKitchen__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .done-icon,
.map .map-view .marker-container .assigned_to_courier-icon {
  background-image: url("../../../../Assets/FoodDone__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .courier_started-icon {
  background-image: url("../../../../Assets/FoodOnRoad__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .order_delivered-icon {
  background-image: url("../../../../Assets/FoodDelivered__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .go_back-icon {
  background-image: url("../../../../Assets/CarBack__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .on_road-icon {
  background-image: url("../../../../Assets/CarOnRoad__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .created-icon {
  background-image: url("../../../../Assets/CarAtRestaurant__sign.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}
.map .map-view .marker-container .courier-data {
  padding: 0.2rem 0.8rem;
  margin-top: 0.5rem;
  font-weight: 600;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
}
.map__loading,
.map__element {
  height: 100%;
}
.map__container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 5rem;
}
.map .unit-marker-container .rest-icon {
  position: absolute;
  height: auto;
  max-width: 20px;
  left: 3.4rem;
  top: 2.5rem;
  bottom: 0;
}
.map__label {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.map__label .courier-data {
  padding: 0.2rem 0.8rem;
  margin-top: 0.5rem;
  font-weight: 600;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
}
.map__base-label {
  margin-left: -3rem !important;
  font-size: 1.2rem;
}
.map .done {
  color: white;
  background-color: #4bd86b;
}
.map .done .courier-data {
  background-color: #2cce51;
}
.map .not-occupied {
  color: white;
  background-color: #ff8713;
}
.map .not-occupied .courier-data {
  background-color: #e97300;
}
.map .order_delivered {
  background-color: #d010ff;
}
.map .order_delivered .courier-data {
  background-color: #9c00c2;
}
.map .courier_started {
  color: black;
  background-color: #29b9ff !important;
}
.map .courier_started .courier-data {
  background-color: #0083c2;
}
.map .go_back {
  color: white;
}
.map .go_back .courier-data {
  background-color: #9c00c2;
}
.map .order-initial-status {
  color: black;
  background-color: #e7ecef;
}
.map .order-initial-status .courier-data {
  background-color: #6d8b9d;
  color: white;
}
.map .common {
  background-color: #777777;
  color: white;
}
.map__info-box {
  background-color: #777777;
  width: 430px;
  height: 250px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-radius: 1rem;
  z-index: 5000 !important;
  cursor: default !important;
}
