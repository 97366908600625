.signs {
  margin-right: -3rem;
}
.signs__header {
  font-size: 2rem;
  border-bottom: 0.1rem solid #99999934;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  font-weight: 600;
}
.signs__list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 300px);
  padding-left: 1.5rem;
  padding-right: 3rem;
}
.signs__element {
  border-bottom: 0.1rem solid #99999934;
  margin: 2rem 0;
  padding-bottom: 2rem;
}
.signs__element--header {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.signs__element:last-of-type {
  border-bottom: none;
}
.signs__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.signs__row--img {
  width: 3.5rem;
  height: auto;
}
.signs__row--text {
  font-size: 1.2rem;
  margin-left: 1rem;
}
