.delete-modal__title {
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 0.1rem solid #99999934;
  padding-bottom: 0.5rem;
  margin-right: 2rem;
}
.delete-modal__text {
  font-size: 1.4rem;
  padding: 1.5rem 0;
  max-width: 40rem;
}
.delete-modal__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-modal__button-container .xa-button:first-of-type {
  margin-right: 2rem;
}
