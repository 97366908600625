.config-view .config-form {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
}
.config-view .config-form .wrapper {
  margin-bottom: 1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.config-view .config-form .xa-button {
  min-width: 10rem;
}
