.archived-orders-view {
  width: 100%;
}
.archived-orders-view__button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 2rem;
  border-bottom: 0.1rem solid #99999934;
  background-color: #ffffff;
}
.archived-orders-view__button-container .xa-button:first-of-type {
  margin-right: 2rem;
}
.archived-orders-view__filter {
  padding: 2rem 4rem;
  position: relative;
  border-bottom: 0.1rem solid #99999934;
}
.archived-orders-view__filter .day-step-arrow-container {
  position: absolute;
  right: 4rem;
  top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.archived-orders-view__filter .day-step-arrow-container .arrow-icon {
  margin: 0 0.3rem;
  color: #777777;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
  cursor: pointer;
  z-index: 1;
}
.archived-orders-view__filter .day-step-arrow-container .arrow-icon::before {
  font-size: 1.6rem;
  display: inline-block;
}
.archived-orders-view__filter .day-step-arrow-container .arrow-icon:hover {
  color: #fb3350;
}
.archived-orders-view__filter .day-step-arrow-container .left-arrow {
  transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
}
.archived-orders-view__filter .day-step-arrow-container .right-arrow {
  transform: rotateZ(-90deg);
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  -ms-transform: rotateZ(-90deg);
  -o-transform: rotateZ(-90deg);
}
.archived-orders-view .orders-view__list-container .archived-order .order__data {
  max-width: 35rem;
}
.archived-orders-view .orders-view__list-container .archived-order .order__payment--timer {
  font-size: 1.2rem;
}
