.login-view {
  position: relative;
  overflow-x: hidden;
}
.login-view__background {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  min-width: 100%;
  z-index: -1;
}
.login-view__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}
.login-view__container--logo {
  max-width: 50rem;
  width: 100%;
  height: auto;
}
.login-view__container .spinner {
  top: 20%;
}
.login-view .login-page {
  margin-top: 15rem;
  background-color: #ffffff;
  max-width: 35rem;
  width: 100%;
  padding: 4rem;
  -webkit-box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.15);
}
.login-view .login-page__form {
  margin-bottom: 2rem;
}
.login-view .login-page__form .wrapper {
  margin-bottom: 1rem;
}
.login-view .login-page__form .xa-button {
  width: 100%;
  margin-top: 1rem;
}
@media screen and (max-width: 576px) {
  .login-view__container {
    padding: 2rem 1rem 2rem 1rem;
  }
  .login-view__container--logo {
    max-width: 30rem;
  }
  .login-view .login-page {
    margin-top: 5rem;
  }
}
