.drawer-view {
  right: 0;
  bottom: 0;
  top: 5rem;
  width: 60rem;
  position: absolute;
  background-color: #e0e7e9;
  height: calc(100vh - 5rem);
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  z-index: 10;
}
.drawer-view .container {
  height: 100%;
  width: 100%;
  position: relative;
}
.drawer-view__controls {
  position: absolute;
  transform: rotateZ(-90deg);
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  -ms-transform: rotateZ(-90deg);
  -o-transform: rotateZ(-90deg);
  left: -3rem;
  bottom: 0;
  width: 3rem;
  z-index: 10;
}
.drawer-view__controls .control-container {
  width: calc(100vh - 5rem);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-left: 5vh;
  padding-right: 5vh;
}
.drawer-view__controls .block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.drawer-view__controls .block:nth-of-type(1) {
  min-width: 27.5rem;
}
.drawer-view__controls .block:nth-of-type(2) {
  min-width: 20.5rem;
}
.drawer-view__controls--navigator {
  padding: 0.55rem 2rem;
  background-color: #a93e73;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 1rem;
  display: block;
  text-align: center;
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.drawer-view__controls--navigator:hover {
  background-color: #bb4680;
}
.drawer-view__controls .dark-nav {
  background-color: #666666;
}
.drawer-view__controls .dark-nav:hover {
  background-color: #737373;
}
.drawer-view__controls .archive-tab {
  font-size: 1.1rem;
  text-align: center;
  background-color: #666666;
  color: #cacaca;
}
.drawer-view__controls .archive-tab:hover {
  background-color: #737373;
}
.drawer-view-closed {
  transform: translateX(60rem);
  -webkit-transform: translateX(60rem);
  -moz-transform: translateX(60rem);
  -ms-transform: translateX(60rem);
  -o-transform: translateX(60rem);
}
.drawer-view-closed .drawer-view__controls .control-container .arrow-navigator {
  transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1rem;
  -moz-border-radius-bottomleft: 1rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
