.infobox-view {
  width: 100%;
}
.infobox-view .infobox__title {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.infobox-view .infobox__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid #99999934;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}
.infobox-view .infobox__header--timer--warning {
  background-color: #fb3350;
  padding: 0.3rem 0.5rem;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
}
.infobox-view .infobox__body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.infobox-view .infobox__body--address {
  font-weight: 600;
}
.infobox-view .infobox__body--address span {
  font-weight: 400;
}
.infobox-view .infobox__body--details {
  text-align: right;
}
.infobox-view .infobox__body--details--name {
  font-weight: 600;
  padding-bottom: 0.5rem;
}
.infobox-view .infobox__body--details--phone {
  padding-bottom: 0.5rem;
}
.infobox-view .infobox__body--details--payment {
  padding-bottom: 2rem;
}
.infobox-view .infobox__body--details--edit-item {
  color: #a93e73;
  font-weight: 600;
  font-size: 1.4rem;
  cursor: pointer;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.infobox-view .infobox__body--details--edit-item:hover {
  color: #963766;
}
.infobox-view .infobox__footer {
  margin-top: 2rem;
  border-top: 0.1rem solid #99999934;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infobox-view .infobox__footer--name {
  background-color: #a93e73;
  color: #ffffff;
  font-weight: 600;
  padding: 0.3rem 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
}
.infobox-view .infobox__footer--swap {
  color: #a93e73;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  -ms-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.infobox-view .infobox__footer--swap:hover {
  color: #963766;
}
