.drawer__header {
  font-size: 1rem;
  text-transform: uppercase;
  position: relative;
  background-color: #ffffff;
  display: flex;
}
.drawer__header--title {
  align-self: center;
  padding: 1rem 0 1rem 1.3rem;
  margin-right: 2rem;
  font-size: 1.4rem;
}
